export const env = {
//  apiHost: "http://localhost:3030/",
//  frontUrl: "http://localhost:3000/",

  // apiHost: "https://wxdlpro.io:3033/",
  // frontUrl: "https://wxdlpro.io/",

  // apiHost: "https://qsxsawesxdfvjklmnvbdxcvb.wxdlpro.io:3030/",
  // frontUrl: "https://wxdlpro.io/",

  apiHost: "https://sdfxhgmnbjfgyhbgjjb.wxdlpro.io:3030/",
  frontUrl: "https://sdfxhgmnbjfgyhbgjjb.wxdlpro.io/",

  upload_preset: "wxdlpro",
  cloud_name: "dt1hydhx5",
};
