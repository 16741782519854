import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";

const Swap = () => {
    const [rankHistory, setrankHistory] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const fetchHistory = async () => {
        try {
            const response = await postMethod({
                apiUrl: apiService.rank_history,
                payload: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                },
            });

            if (response.success) {
                setrankHistory(response.data.data);
                setTotalPages(response.data.pages);
            } else {
                setrankHistory([]);
                setTotalPages(0);
            }
        } catch (error) {
            console.error("Error fetching deposit history:", error);
            toast.error("Failed to fetch data.");
        }
    };

    useEffect(() => {
        fetchHistory();
    }, [currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const convertUTCToIST = (utcDateString) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };

        const istDate = new Date(utcDateString).toLocaleString("en-IN", options);

        return istDate;
    };

    return (
        <>
            <div className="inner_header">
                <InnerHeader />
            </div>
            <div className="swap_main p2p_table_content">
               
                <div className="dashboard_table_content container mt-3">
                    <div className="deposit_history_content">
                        <h3 className="referral_title">Leader Board</h3>
                    </div>
                    <div className="table_overflow">
                        <table className="wxdl_dashboard three table">
                            <thead>
                                <tr>
                                    <th scope="col" className="start">
                                        SI.No
                                    </th>
                                    {/* <th scope="col">Date & Time</th> */}
                                    <th scope="col">UserID</th>
                                    <th scope="col">Name</th>
                                    {/* <th scope="col">Email</th> */}
                                    <th scope="col">Rank 1</th>
                                    <th scope="col">Rank 2</th>
                                    <th scope="col">Rank 3</th>
                                    <th scope="col">Rank 4</th>
                                    <th scope="col">Rank 5</th>
                                    <th scope="col">Rank 6</th>
                                    <th scope="col">Rank 7</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {rankHistory.length > 0 ? (
                                    rankHistory.map((item, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i + 1}</th>
                                            {/* <td>{convertUTCToIST(item.updatedAt)}</td> */}
                                            <td>
                                            {item.internalID}
                                            </td>
                                            <td>
                                            {item.ranks[0].username}
                                            </td>
                                            {/* <td>
                                            {item.ranks[0].email}
                                            </td> */}
                                            {/* <td>
                                            <div class="rank-container">
                                            {item.ranks.map((item1, i) => (
                                            <div class="rank">
                                                <span class="rank-name">{item1.rank_name}</span>
                                                <img src={item1.rank_logo} class="rank-logo" />
                                            </div>
                                            ))}
                                            </div>
                                           </td> */}
                                           {item.ranks.slice(0,7).map((item1, i) => (
                                            <td>
                                            <div class="rank-container">
                                            <div class="rank">
                                                <span class="rank-name">{item1.rank_name}</span>
                                                <img src={item1.rank_logo} />
                                            </div>
                                            </div>
                                           </td>
                                           ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={9} className="background_trans">
                                            <div className="empty_data">
                                                <div className="no_records_text">No Records Found</div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {totalPages > 0 && (
                        <div className="pagination">
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                className="table_pagination"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Swap;
