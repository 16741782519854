import React, { useEffect } from "react";
import useState from "react-usestateref";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";

export default function Deposit() {
  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  // const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState("");

  const nav_page = async (link) => {
    navigate(link);
  };

  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      showsuccessToast(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      showerrorToast(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    password: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);

  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfa] = useState(false);

  const [validationnErr, setvalidationnErr] = useState("");

  const { amount, withAddress, password, tfa } = formValue;

  useEffect(() => {
    getAllcurrency();
    get_address();
    // getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);




  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");

    if (name == "tfa") {
      if (value.length <= 6) {
        let formData = { ...formValue, ...{ [name]: sanitizedValue } };
        setFormValue(formData);
        validate_preview(formData);
      }
    } else {
      let formData = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(formData);
      validate_preview(formData);
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      var currArrayCrypto = [];
      var data = resp.data;
      if(data.length > 0)
      {
        setallCrypto(data);
        for (var i = 0; i < data.length; i++) {
          if (data[i].withdrawStatus == "Active") {
            if (data[i].currencySymbol == "WXDL") {
              var obj = {
                value: data[i]._id,
                label: data[i].currencySymbol,
                coinType: data[i].coinType,
                key: data[i]._id,
                disabled: true,
                text: data[i].currencySymbol,
                image: { avatar: true, src: data[i].Currency_image },
                erc20token: data[i].erc20token,
                bep20token: data[i].bep20token,
                trc20token: data[i].trc20token,
                rptc20token: data[i].rptc20token,
              };
            } else {
              var obj = {
                value: data[i]._id,
                label: data[i].currencySymbol,
                coinType: data[i].coinType,
                key: data[i]._id,
                text: data[i].currencySymbol,
                image: { avatar: true, src: data[i].Currency_image },
                erc20token: data[i].erc20token,
                bep20token: data[i].bep20token,
                trc20token: data[i].trc20token,
                rptc20token: data[i].rptc20token,
              };
            }
  
            currArrayCrypto.push(obj);
          }
        }
        setallCurrency(currArrayCrypto);
      }
     
    }
  };

  const onSelect = async (e, option) => {
    console.log(option, "option");
    formValue.withAddress = null;
    setwithdrawAddress("");
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setnet_current("");
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      showerrorToast("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];

        console.log(currencydata, "currencydata");

        setcurrency(currencydata.currencySymbol);
        setcointype(currencydata.coinType);
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "bep20token",
              text: "BEP20",
              key: "bep20token",
            };
            network_names.push(network_cur);
          }

          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: option.value,
        };

        console.log(obj, "obj");
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);

          console.log(resp.data, "Balanceref");
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const validate_preview = async (values) => {
    const errors = {};
    const address = values.withAddress;

    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    } else if (!isValidBNBAddress(address)) {
      errors.withAddress = `Invalid BNB (BEP-20) address`;
      setwithAddress(true);
    } else {
      setwithAddress(false);
    }

    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    } else if (values.amount > 100000) {
      errors.amount = "Amount must be less than 100,000";
      setamountValidate(true);
    } else {
      setamountValidate(false);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 21) {
      errors.password = "Password must be between 5 and 21 characters";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (!values.tfa) {
      errors.tfa = "2FA Code is a required field";
      settfa(true);
    } else {
      settfa(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const isValidBNBAddress = (address) => {
    const bnbAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return bnbAddressRegex.test(address);
  };


  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 5;

  useEffect(() => {
    getWithdrawHistory(currentPage);
  }, [currentPage]);



  const getWithdrawHistory = async (page) => {
    try {
      const data = {
        apiUrl: apiService.withdraw_history,
        payload: { FilPerpage: recordsPerPage, FilPage: page },
      };
      const withdraw_history_list = await postMethod(data);

      if (withdraw_history_list && withdraw_history_list.status) {
        setWithdrawHistory(withdraw_history_list.result);
        setTotalPages(Math.ceil(withdraw_history_list.pages / recordsPerPage));
      } else {
        setWithdrawHistory([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const onSelect_network = async (e, option) => {
    setnet_current(option.label);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const onSelect_address = async (e, option) => {
    console.log("option.value",option.value);
    console.log("option.value 1",option.value.slice(0,10));
    setwithdrawAddress(option.value.slice(0,10));
    formValue.withAddress = option.value;
  };

  const withdrawSubmit = async () => {
    try {
      validate_preview(formValue);
      if (
        formValue.amount != "" &&
        formValue.withAddress != "" &&
        formValue.tfa != ""
      ) {
        if (+formValue.amount > 0) {
          if (currentcurrency.minWithdrawLimit > formValue.amount) {
            showerrorToast(
              "Please enter greater than " +
              currentcurrency.minWithdrawLimit +
              " amount"
            );
          } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
            showerrorToast(
              "Please enter less than " +
              currentcurrency.maxWithdrawLimit +
              " amount"
            );
          } else {
            var obj = {
              amount: formValue.amount,
              withdraw_address: formValue.withAddress,
              Tfa: formValue.tfa,
              password: password,
              currency_symbol: currentcurrency.currencySymbol,
              currId: currentcurrency._id,
              network: network_currentref.current,
            };
            // console.log("submit withdraw params---",obj);
            // return;
            var data = {
              apiUrl: apiService.submitWithdraw,
              payload: obj,
            };
            setbuttonLoader(true);
            var resp = await postMethod(data);
            if (resp.status) {
              showsuccessToast(resp.message);
              // getwithdrawHistory(1);
              setbuttonLoader(false);
              setshow_otp(false);
              formValue.amount = "";
              setwithdrawAddress("");
              formValue.tfa = "";
              formValue.withdraw_otp = "";
              //window.location.reload(false);
            } else {
              showerrorToast(resp.message);
              setbuttonLoader(false);
            }
          }
        } else {
          showerrorToast("Please give valid withdraw amount!");
        }
      }
    } catch (error) { }
  };

  const [alertTab, setalertTab] = useState("hide");
  const [withdraw_address, setwithdrawalAddress, withdraw_addressref] = useState([]);

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  const get_address = async () => {
    try {
      const data = {
        apiUrl: apiService.get_withdraw_address
      };
      const withdraw_history_list = await getMethod(data);

      if (withdraw_history_list && withdraw_history_list.status) {
        setwithdrawalAddress(withdraw_history_list.data);
      } else {
        setwithdrawalAddress([]);
      }
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="deposit_main">
        <div className="container">
          <div className="">
            <p class="titlr_lable_dd text-start pt-5">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>

              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <a href="/withdrawaddress" class="button_home">
              <small>Withdraw Address Update</small>
              </a>
              
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Withdraw</small>

              
            </p>
          </div>

          <div className="p2p_table_content">
            <div className="row">
              <div className="col-lg-5 wxdl_deposit">
                <div className="wxdl_deposit_content">
                  <h3>Currency</h3>
                  <Dropdown
                    inline
                    options={allCurrencyref.current}
                    onChange={onSelect}
                    defaultValue={currencyref.current}
                    isSearchable={true}
                    placeholder="Select Coin"
                    

                  // options={allCurrency}
                  // value={selectedCurrency}
                  // onChange={selectToken}
                  />
                </div>

                {show_otpref.current == false &&
                  currentcurrencyref.current.currencyType == "2" ? (
                  <div className="wxdl_deposit_content">
                    <h3>Choose a Network</h3>
                    <Dropdown
                      inline
                      options={network_currencyref.current}
                      placeholder="Select an Network"
                      onChange={onSelect_network}
                      defaultValue={net_defaultref.current}
                      isSearchable={true}

                    // options={allCurrency}
                    // value={selectedCurrency}
                    // onChange={selectToken}
                    />
                  </div>
                ) : (
                  ""
                )}

                {withdrawType == "1" && show_otpref.current == false ? (
                  <>
                    <div className="wxdl_deposit_content">
                      {/* <h3>Address</h3>
                      <input
                        name="withAddress"
                        type="text"
                        value={withAddress}
                        onChange={handleChange}
                        placeholder="Enter Your Address"
                        className="withdraw_input_address"
                      /> */}

                    <Dropdown
                      inline
                      options={withdraw_addressref.current}
                      placeholder="Select Withdraw Address"
                      onChange={onSelect_address}
                      defaultValue={withdrawAddressref.current}
                      isSearchable={true}
                    />
                    </div>
                    {withAddressValidate == true ? (
                      <p className="errorcss"> {validationnErr.withAddress} </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {show_otpref.current == false ? (
                  <>
                    <h3 className="witthdraw_amt_title">Amount</h3>
                    <div className="currency_copy_content">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        placeholder="Enter Amount"
                        name="amount"
                        value={amount}
                        min="0"
                        minLength="0"
                        onChange={handleChange}
                      />
                      USDT
                    </div>
                    {amountValidate == true ? (
                      <p className="errorcss"> {validationnErr.amount} </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {show_otpref.current == false ? (
                  <>
                    <h3 className="witthdraw_amt_title">
                      Transaction Password
                    </h3>
                    <div className="currency_copy_content">
                      <input
                        type={inputType}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        placeholder="Enter Your Password"
                      />
                      {passHide == true ? (
                        <i
                          className="fa-regular fa-eye reg_eye"
                          onClick={() => passwordHide("show")}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash reg_eye"
                          onClick={() => passwordHide("hide")}
                        ></i>
                      )}
                    </div>
                    {passwordValidate && (
                      <p className="errorcss">{validationnErr.password}</p>
                    )}
                  </>
                ) : (
                  ""
                )}

                {withdrawType == "1" && show_otpref.current == false ? (
                  <>
                    <div className="wxdl_deposit_content">
                      <h3>2FA Code</h3>
                      <input
                        name="tfa"
                        type="Number"
                        value={tfa}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   if (value.length <= 6) {
                        //     setFormValue({ tfa: value });
                        //     settfa
                        //   } else {
                        //     settfa(false)
                        //   }
                        // }}
                        placeholder="Enter Your 2FA code"
                        className="withdraw_input_address"
                      />
                    </div>
                    {tfaValidate == true ? (
                      <p className="errorcss"> {validationnErr.tfa} </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                <div className="remainder_content">
                  <h3>Reminder</h3>
                  <div className="min_dep_text">
                    <p>Available Balance</p>
                    <p>
                      {balanceref.current.balance} {currencyref.current}
                    </p>
                  </div>

                  <div className="min_dep_text">
                    <p>Minimum Withdraw</p>
                    <p>
                      {currentcurrencyref.current.minWithdrawLimit}{" "}
                      {currencyref.current}
                    </p>
                  </div>
                  <div className="min_dep_text">
                    <p>Fees</p>
                    <p>10%</p>
                  </div>
                </div>
                <div className="withdrawal_button">
                  {localStorage.getItem("tfa_status") == 0 ? (
                    <button onClick={() => nav_page("/create-2fa")}>
                      Enable 2FA
                    </button>
                  ) : (
                    buttonLoader === true ? (
                      <button >Loading</button>
                    ) : (
                      <button onClick={() => withdrawSubmit()}>Withdraw</button>
                    )
                    
                  )}
                </div>
              </div>
            </div>
            <div className="dashboard_table_content mt-5">
              <div className="deposit_history_content">
                <h3 className="referral_title">Withdrawal History</h3>
                {/* <p>View all</p> */}
              </div>
              <div className="table_overflow">
                <table class="wxdl_dashboard table ">
                  <thead>
                    <tr>
                      <th scope="col" className="start">
                        SI.No
                      </th>
                      <th scope="col">Currency</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col" className="end">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawHistory && withdrawHistory.length > 0 ? (
                      withdrawHistory.map((item, i) => {
                        return (
                          <tr>
                            <th scope="row"> {i + 1} </th>
                            <td className="usdt_currency_items">
                              <img
                                src={require("../assets/images/dash_icon/usdt_coin.png")}
                              />
                              <p>{item.currency}</p>
                            </td>
                            <td>{parseFloat(item.amount).toFixed(8)}</td>
                            <td>{convertUTCToIST(item.created_at)}</td>
                            <td>
                              {item.txn_id}{" "}
                              <i
                                class="ri-file-copy-line"
                                onClick={() => copy(item.txn_id)}
                              ></i>
                            </td>
                            {item.status == "Pending" ? (
                              <td className="text-danger"> {item.status} </td>
                            ) : (
                              <td className="text-green"> {item.status} </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6"> No Data found! </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  className="table_pagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
