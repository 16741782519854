import React, { useEffect } from "react";
import useState from "react-usestateref";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";

import Select from "react-select";

export default function Deposit() {
  const navigate = useNavigate();
  const [buttonLoader, setbuttonLoader] = useState(false);


  const initialFormValue = {
    internalID: "",
    xdreamlife_id: "",
    amount: "",
    txnid: "",
    password: ""
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [internalIDValidate, setinternalIDValidate] = useState(false);
  const [xdreamlifeIDValidate, setxdreamlifeIDValidate] = useState(false);
  const [amountValidate, setamountValidate] = useState(false);
  const [txnidValidate, settxnidValidate] = useState(false);

  const [passwordValidate, setpasswordValidate] = useState(false);

  const [validationnErr, setvalidationnErr] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const [Username, setUsername] = useState("");

  const [wxdlID, setwxdlID] = useState("");


  const [TokenValue, setTokenValue] = useState("");

  const { internalID, xdreamlife_id, amount, txnid, password } = formValue;

  useEffect(() => {
    getProfile();
  }, [0]);



  const getProfile = async () => {
    try {
      var datas = {
        apiUrl: apiService.getuserdetail,
      };
      var responce = await getMethod(datas);
      if (responce != null) {
        if(responce.status)
        {
          setwxdlID(responce.data.internalID);
          formValue.internalID = responce.data.internalID;
        }
        
      }
    } catch (error) {
      console.log(error, "error");
      // toast.success("Please try  later!");
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
      let formData = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(formData);
      validate_preview(formData);
      if (name === "xdreamlife_id") {

        console.log("xddream ",value.length)
        var data = {
          apiUrl: apiService.find_xdlid,
          payload: { xdreamlife_id: value },
        };
  
        var response = await postMethod(data);
  
        if (response.success) {
          setUsername(response.username);
        } else {
          setUsername("User not found");
  
        }
      }
      
  };

  const handleChange_id = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    console.log("value",value)
    if(value != null)
    {
      // if(value.length > 4)
      // {
        var data = {
          apiUrl: apiService.find_xdlid,
          payload: { xdreamlife_id: value },
        };
  
        var response = await postMethod(data);
  
        if (response.success) {
          setUsername(response.username);
          
        } else {
          setUsername("User not found");
  
        }
      //}
    }
       
  };

  const validate_preview = async (values) => {
    const errors = {
      internalID: "",
      xdreamlife_id: "",
      amount: "",
      txnid: "",
      password: ""
    };

    if (!values.internalID) {
      errors.internalID = "XDL UserId is a required field";
      setinternalIDValidate(true);
    }
    else {
      errors.internalID = "";
      setinternalIDValidate(false);
    }

    if (!values.xdreamlife_id) {
      errors.xdreamlife_id = "Xdreamlife UserId is a required field";
      setxdreamlifeIDValidate(true);
    }
    else {
      errors.xdreamlife_id = "";
      setxdreamlifeIDValidate(false);
    }

    if (!values.txnid) {
      errors.txnid = "Transaction ID is a required field";
      settxnidValidate(true);
    }
    else {
      errors.txnid = "";
      settxnidValidate(false);
    }

    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    } 
    else {
      errors.amount = "";
      setamountValidate(false);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 21) {
      errors.password = "Password must be between 5 and 21 characters";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const [transctionHistory, settransctionHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 5;

  const amount_options = [
    {
      value: 810,
      label: "(50$) 810 XDL",
      key: 9,
    },
    {
      value: 1350,
      label: "(100$) 1350 XDL",
      key: 15,
    },
    {
      value: 1800,
      label: "(200$) 1800 XDL",
      key: 20,
    },
    {
      value: 2250,
      label: "(300$) 2250 XDL",
      key: 25,
    },
    {
      value: 3150,
      label: "(500$) 3150 XDL",
      key: 35,
    },
  ];

  useEffect(() => {
    geHistory(currentPage);
  }, [currentPage]);



  const geHistory = async (page) => {
    try {
      const data = {
        apiUrl: apiService.xdlgiveaway_history,
        payload: { FilPerpage: recordsPerPage, FilPage: page },
      };
      const history_list = await postMethod(data);

      if (history_list && history_list.status) {
        settransctionHistory(history_list.result);
        setTotalPages(Math.ceil(history_list.pages / recordsPerPage));
      } else {
        settransctionHistory([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const transactionSubmit = async () => {
    try {
      validate_preview(formValue);
      if (
        formValue.internalID != "" &&
        formValue.xdreamlife_id != "" &&
        formValue.txnid != "" &&
        formValue.amount != "" && 
        formValue.password != ""
      ) {
        if(Username != "User not found")
        {
            if (+formValue.amount > 0) {
              var obj = {
                internalID: formValue.internalID,
                xdreamlife_id: formValue.xdreamlife_id,
                txnid: formValue.txnid,
                amount: formValue.amount,
                password: password
              };
              // console.log("submit withdraw params---",obj);
              // return;
              var data = {
                apiUrl: apiService.submitTransaction,
                payload: obj,
              };
              setbuttonLoader(true);
              var resp = await postMethod(data);
              if (resp.status) {
                showsuccessToast(resp.message);
                // getwithdrawHistory(1);
                setbuttonLoader(false);
                formValue.amount = "";
                formValue.internalID = "";
                formValue.xdreamlife_id = "";
                formValue.txnid = "";
              } else {
                showerrorToast(resp.message);
                setbuttonLoader(false);
              }
          } else {
            showerrorToast("Please give valid amount!");
          }
        }
        else
        {
          setbuttonLoader(false);
          formValue.amount = "";
          formValue.internalID = "";
          formValue.xdreamlife_id = "";
          formValue.txnid = "";
          showerrorToast("Xdreamlife UserID not found!");
        }
        
      }
    } catch (error) { }
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  const handleChangeDrop = (e) => {
    formValue.amount = e.value;
    setSelectedOption(e);
    console.log(e.label, "=-=-==-=-=-=selectedOption==-==-=");
    setTokenValue(e.key)
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="deposit_main">
        <div className="container">
          <div className="">
            <p class="titlr_lable_dd text-start pt-5">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>XDL Giveaway</small>
            </p>
          </div>
          <div className="p2p_table_content">
            <div className="row">
              <div className="col-lg-5 wxdl_deposit">

                    <div className="wxdl_deposit_contend">
                    <div className="net_address_qr remainder_content mt-4">
                      <h3>Address</h3>

                      <img
                        src="https://quickchart.io/chart?cht=qr&chs=280x280&chl=0xdfb8Fa22C7F944ccF87b50813804D8B267f3C93B&choe=UTF-8&chld=L"
                        className="qr_bg"
                      />

                      {/* <p>This address only accepts deposits in USDT.</p> */}
                    </div>

                    <div className="currency_copy_content">
                      <input
                        type="text"
                        value="0xdfb8Fa22C7F944ccF87b50813804D8B267f3C93B"
                      />
                      <i
                        class="ri-file-copy-line"
                        onClick={() => copy("0xdfb8Fa22C7F944ccF87b50813804D8B267f3C93B","Address")}
                      ></i>
                    </div>
                    </div>
                     
                    

                   


                   

                    <h3 className="witthdraw_amt_title">Token</h3>
                    <div className="req_field_sin">
                      {/* <input
                        type="number"
                        pattern="[0-9]*"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        placeholder="Enter Amount"
                        name="amount"
                        value={amount}
                        min="0"
                        minLength="0"
                        onChange={handleChange}
                      /> */}
                      <Select
                          name="amount"
                          onChange={handleChangeDrop}
                          options={amount_options}
                          value={selectedOption}
                          className="form-control_newsect"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                              ...theme.colors,
                              primary25: "#761fe3",
                              primary: "#761fe3",
                            },
                          })}
                        />
                    </div>
                    {amountValidate == true ? (
                      <p className="errorcss"> {validationnErr.amount} </p>
                    ) : (
                      ""
                    )}

                {TokenValue != "" ? (
                    <h3 className="witthdraw_amt_title text-success">Value: {TokenValue}$</h3>

                  ) : (
                    ""
                  )}

                    <div className="wxdl_deposit_content">
                      <h3>WXDL UserID</h3>
                      <input
                        name="internalID"
                        type="text"
                        value={internalID}
                        onChange={handleChange}
                        placeholder="Enter Your WXDL UserID"
                        className="withdraw_input_address"
                        disabled
                      />
                    </div>
                    {internalIDValidate == true ? (
                      <p className="errorcss"> {validationnErr.internalID} </p>
                    ) : (
                      ""
                    )}

                   <div className="wxdl_deposit_content">
                      <h3>Xdreamlife UserID</h3>
                      <input
                        name="xdreamlife_id"
                        type="text"
                        value={xdreamlife_id}
                        onChange={handleChange}
                        placeholder="Enter Your Xdreamlife UserID"
                        className="withdraw_input_address"
                      />
                    </div>
                    {xdreamlifeIDValidate == true ? (
                      <p className="errorcss"> {validationnErr.xdreamlife_id} </p>
                    ) : (
                      ""
                    )}
                     {Username == "User not found" ? (
                    <h3 className="witthdraw_amt_title text-danger">{Username}</h3>

                  ) : (
                    <h3 className="witthdraw_amt_title text-success">{Username}</h3>

                  )}

                    <div className="wxdl_deposit_content">
                      <h3>Transaction ID</h3>
                      <input
                        name="txnid"
                        type="text"
                        value={txnid}
                        onChange={handleChange}
                        placeholder="Enter Your Transaction ID"
                        className="withdraw_input_address"
                      />
                    </div>
                    {txnidValidate == true ? (
                      <p className="errorcss"> {validationnErr.txnid} </p>
                    ) : (
                      ""
                    )}

                    <h3 className="witthdraw_amt_title">
                      Transaction Password
                    </h3>
                    <div className="currency_copy_content">
                      <input
                        type={inputType}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        placeholder="Enter Your Password"
                      />
                      {passHide == true ? (
                        <i
                          className="fa-regular fa-eye reg_eye"
                          onClick={() => passwordHide("show")}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash reg_eye"
                          onClick={() => passwordHide("hide")}
                        ></i>
                      )}
                    </div>
                    {passwordValidate && (
                      <p className="errorcss">{validationnErr.password}</p>
                    )}
                <div className="remainder_content">

                </div>
                <div className="withdrawal_button">
                  {buttonLoader === true ? (
                      <button >Loading</button>
                    ) : (
                      <button onClick={() => transactionSubmit()}>Submit</button>
                    )}
                </div>
              </div>
            </div>
            <div className="dashboard_table_content mt-5">
              <div className="deposit_history_content">
                <h3 className="referral_title"> History</h3>
                {/* <p>View all</p> */}
              </div>
              <div className="table_overflow">
                <table class="wxdl_dashboard table ">
                  <thead>
                    <tr>
                      <th scope="col" className="start">
                        SI.No
                      </th>
                      <th scope="col">Xdreamlife UserID</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col" className="end">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transctionHistory && transctionHistory.length > 0 ? (
                      transctionHistory.map((item, i) => {
                        return (
                          <tr>
                            <th scope="row"> {i + 1} </th>
                            <td className="usdt_currency_items">
                              {item.xdreamlife_id}
                            </td>
                            <td>{parseFloat(item.amount).toFixed(8)}</td>
                            <td>
                              {item.txnid}{" "}
                              <i
                                class="ri-file-copy-line"
                                onClick={() => copy(item.txnid)}
                              ></i>
                            </td>
                            <td>{convertUTCToIST(item.date)}</td>
                            
                            {item.status == "Pending" ? (
                              <td className="text-danger"> {item.status} </td>
                            ) : (
                              <td className="text-green"> {item.status} </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6"> No Data found! </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  className="table_pagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
